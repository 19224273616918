import React from 'react';

const RocketIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20">
      <path fill="#F9D026" d="M171.181,340.819c-52.5-52.5-118.497,43.543-118.497,43.543l24.597-7.532l-53.691,63.3l35.351-9.502
	      L0,511.999l81.371-58.94l-9.502,35.35l63.299-53.691l-7.532,24.597C127.637,459.316,223.681,393.319,171.181,340.819z"/>
      <path fill="#E7C224" d="M171.181,340.819L0,511.999l81.371-58.94l-9.502,35.35l63.299-53.691l-7.532,24.597
	      C127.637,459.316,223.681,393.319,171.181,340.819z"/>
      <polygon fill="#F74D37" points="183.216,222.837 53.806,201.025 121.794,133.038 251.204,154.849 "/>
      <polygon fill="#C43D2C" points="289.163,328.785 310.975,458.195 378.963,390.207 357.151,260.798 "/>
      <path fill="#EDEEF0" d="M465.429,159.887C508.154,86.257,512,0.005,512,0.002c0,0-86.254,3.843-159.885,46.57
        c-16.993,9.862-33.312,21.791-47.746,36.225c0,0-135.974,135.974-160.266,160.266c-24.293,24.293-24.9,68.627,15.655,109.182
        c40.555,40.555,84.888,39.947,109.182,15.655c24.292-24.292,160.266-160.266,160.266-160.266
        C443.638,193.199,455.569,176.88,465.429,159.887z"/>
      <path fill="#DCDEE2" d="M465.429,159.887C508.154,86.257,512,0.005,512,0.002L408.772,103.23L206.521,305.481l-46.763,46.763
        c40.555,40.555,84.888,39.947,109.182,15.655c24.292-24.292,160.266-160.266,160.266-160.266
        C443.638,193.199,455.569,176.88,465.429,159.887z"/>
      <path fill="#F74D37" d="M512,0.002c0,0-86.254,3.843-159.885,46.57l56.657,56.657l56.657,56.657
      	C508.154,86.257,512,0.005,512,0.002z"/>
      <path fill="#444242" d="M206.52,305.481l-62.418-62.418c-24.293,24.293-24.9,68.627,15.655,109.182
	      s84.888,39.947,109.182,15.655L206.52,305.481z"/>
      <path fill="#3A3839" d="M268.938,367.899l-62.418-62.418l-46.763,46.763C200.311,392.799,244.646,392.191,268.938,367.899z"/>
      <circle fill="#BCE8F7" cx="344.932" cy="167.068" r="40.764"/>
      <polygon fill="#F74D37" points="273.048,238.951 273.048,238.951 167.215,314.433 91.733,420.267 91.731,420.268
        197.566,344.786 "/>
      <g>
	      <polygon fill="#C43D2C"
           points="197.566,344.786 273.048,238.951 273.048,238.951 91.733,420.267 91.731,420.268 	"/>
        <path fill="#C43D2C" d="M512,0.001L408.772,103.229l56.657,56.657C508.154,86.257,512,0.005,512,0.001z"/>
      </g>
      <path fill="#8EC1D6" d="M373.756,195.894c15.919-15.919,15.919-41.729,0-57.649l-57.649,57.649
	      C332.025,211.814,357.836,211.814,373.756,195.894z"/>
    </svg>
  );
};

export default RocketIcon
