import { createApiRef } from '@backstage/core-plugin-api'

export type App = {
  id: number
  name: string
  repo: string
  codeVersion: string
  stagingVersion: string
  hotfixVersion: string
  prodVersion: string
}

export type Issue = {
  id: string
  title: string
}

export type Commit = {
  commitSha: string
  message: string
}

export type Release = {
  id: number
  status: string
  version: string
  branch: string
  appName: string
  repo: string
  approvals: string
  updated_at: string
  issues: Issue[]
  commits: Commit[]
}

export type ReleaseHistory = {
  id: number
  version: string
  previousVersion: string
  created: string
  deployed: string
  issues: Issue[]
  commits: Commit[]
  appName: string
  repo: string
}

export interface ReleaseManagerApi {
  getHealth(): Promise<{ status: string }>
  getApps(): Promise<App[]>
  getRelease(appId: number, version: string): Promise<Release>
  queryCompletedReleases(deployedDateStart: string, deployedDateEnd?: string): Promise<ReleaseHistory[]>
}

export const releaseManagerApiRef = createApiRef<ReleaseManagerApi>({
  id: 'plugin.release-manager.service',
})
