import { createRouteRef } from '@backstage/core-plugin-api';
import {Release} from "./api/types";

export const homeRouteRef = createRouteRef({
  id: 'release-manager:home-ref',
});

export const releaseShowRouteRef = createRouteRef({
  id: 'release-manager:release-ref',
  params: ['appId', 'version']
})
